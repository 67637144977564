document.addEventListener('bookPageLoaded', function (e) {

	var faqName = e.detail.faqName;

	if ( typeof faqName !== 'undefined' && faqName.indexOf('NEK') > -1 ) {

		// reset faq iframe after the book is changed
		if ( typeof window.nekActiveFAQName !== 'undefined' ) {
			if (faqName != window.nekActiveFAQName) {
				nekOverlayDiv.innerHTML = '';
			}
		}
		window.nekActiveFAQCategory = faqName;
		document.getElementById('nek-anchored-button').classList.remove('nek-hidden');
	} else {
		faq_close_on_page_change();
		document.getElementById('nek-anchored-button').classList.add('nek-hidden');
	}

	// Check if window.nekActiveFAQCategory contains "variant", wich is separated by a semicolon. 
	if (typeof window.nekActiveFAQCategory !== 'undefined' && window.nekActiveFAQCategory.indexOf(':') !== -1) {
		window.nekActiveFAQName = window.nekActiveFAQCategory;
		window.nekActiveFAQCategoryVariant = window.nekActiveFAQCategory.split(':')[1];
		window.nekActiveFAQCategory = window.nekActiveFAQCategory.split(':')[0];
	}

});

var faqBaseUrl = 'https://www.nek.no';

if (document.getElementById('nek-embedded-faq-script').getAttribute('data-nek-faq-test-url') !== null) {
	testFaqBaseUrl = document
		.getElementById('nek-embedded-faq-script')
		.getAttribute('data-nek-faq-test-url');
	if (
		testFaqBaseUrl === 'https://nek.staging.wpengine.com' ||
		testFaqBaseUrl === 'https://nekdev.wpengine.com' ||
		testFaqBaseUrl === 'http://nek.test'
	) {
		faqBaseUrl = testFaqBaseUrl;
	}
}

function nekHttpGetAsync(url, showLinksCallback) {
	var xmlHttp = new XMLHttpRequest();
	xmlHttp.open('GET', url, true); // true for asynchronous
	xmlHttp.onload = function () {
		var response = JSON.parse(xmlHttp.response);
		if (response.success === true) showLinksCallback(response);
	};
	xmlHttp.send(null);
}

function nekEnableQuicksearchLinks(response) {
	if (typeof response.data === 'undefined' && typeof response.data.sections === 'undefined')
		return false;
	var quickSearchLinks = document.querySelectorAll('.nek-faq-quicksearch');
	var sections = response.data.sections;
	for (var i = 0; i < quickSearchLinks.length; i++) {
		var section = quickSearchLinks[i].getAttribute('data-searchtext');
		if (sections.includes(section)) {
			quickSearchLinks[i].style.display = 'inline-block';
			quickSearchLinks[i].setAttribute('aria-hidden', 'false');
		}
	}
}

function nekPostMsgForSearchFaq(el) {
	el.preventDefault();
	var targetElement = typeof el.srcElement !== 'undefined' ? el.srcElement : el.target;
	var searchtext = targetElement.dataset.searchtext;
	var nekActiveFAQCategory = window.nekActiveFAQCategory;
	var nekActiveFAQCategoryVariant = window.nekActiveFAQCategoryVariant;
	nekActiveFAQCategory = nekActiveFAQCategory.toLowerCase();
	nekActiveFAQCategory = nekActiveFAQCategory.replace(' ', '-');
	
	faq_slide_in(nekActiveFAQCategory, searchtext);

	var iframe = document.getElementById('nek-faq-iframe');
	if (iframe === null) return;

	iframe.contentWindow.postMessage(
		{ faqEventName: 'search', s: searchtext, cat: nekActiveFAQCategory },
		postMessageTargetDomain
	);
}

var postMessageTargetDomain = '*', // window.location.protocol + '//' + window.location.hostname;
	nekOverlayDiv;

document.addEventListener('pageReady', function () {
	if (document.getElementById('nek-embedded-faq-script').getAttribute('data-autoload-button') === 'true') {
		var faqButton = document.createElement('a');
		faqButton.href = '#';
		faqButton.id = 'nek-anchored-button';
		faqButton.classList.add('nek-faq-btn');
		faqButton.classList.add('nek-hidden');
		faqButton.style.display = 'none';
		faqButton.tabIndex = 0;
		faqButton.innerHTML = '<span>Vis FAQ</span>';
		faqButton.setAttribute('role', 'button');
		document.body.appendChild(faqButton);
	}
	nekOverlayDiv = document.createElement('div');
	nekOverlayDiv.id = 'nek-overlay';
	document.body.appendChild(nekOverlayDiv);
});

function nekHideShowAutoloadFAQ() {
	if (this.classList.contains('nek-hidden')) {
		this.classList.remove('nek-hidden');
		this.style.display = 'block';
	} else {
		this.classList.add('nek-hidden');
		this.style.display = 'none';
	}
}


var nekFaqIframe = false,
	embeddedButton;

document.addEventListener('pageReady', function () {
	var link = document.createElement('link');
	link.href = faqBaseUrl + '/embedded/css/styles.css';
	link.type = 'text/css';
	link.rel = 'stylesheet';
	link.media = 'screen';
	document.getElementsByTagName('head')[0].appendChild(link);

	if (typeof embeddedButton !== 'undefined' && embeddedButton !== null) {
		embeddedButton.style.display = 'block';
	}
	var elements = document.getElementsByClassName('nek-faq-btn');
	for (var i = 0, len = elements.length; i < len; i++) {
		elements[i].onclick = function (e) {
			e.preventDefault();
			if (!nekOverlayDiv.classList.contains('nek-active')) {
			var nekActiveFAQCategory = window.nekActiveFAQCategory;
			var nekActiveFAQCategoryVariant = window.nekActiveFAQCategoryVariant;
			nekActiveFAQCategory = nekActiveFAQCategory.toLowerCase();
			nekActiveFAQCategory = nekActiveFAQCategory.replace(' ', '-');
			nekActiveFAQCategory = nekActiveFAQCategory !== undefined ? nekActiveFAQCategory : 'nek-400';
			faq_slide_in(nekActiveFAQCategory, '', nekActiveFAQCategoryVariant);
			
		} else {
				nekOverlayDiv.classList.remove('nek-active');
			}
		};
	}

	embeddedButton = document.getElementById('nek-anchored-button');

	if (typeof embeddedButton !== 'undefined' && embeddedButton !== null) {
		embeddedButton.addEventListener('click', nekHideShowAutoloadFAQ);
		embeddedButton.classList.remove('nek-hidden');
		embeddedButton.style.display = 'inline-block';
	}
});

function faq_close_on_page_change() {
	if (nekOverlayDiv.classList.contains('nek-active')) {
		nekOverlayDiv.classList.remove('nek-active');
		nekOverlayDiv.classList.add('nek-animate-out');
		window.setTimeout(function () {
			nekOverlayDiv.classList.remove('nek-animate-out');
		}, 300);
	}
}

document.addEventListener('bookPageLoaded', function (e) {
	faq_close_on_page_change();

	var category = window.nekActiveFAQName;
	var categoryVariant;
	
	if (typeof category !== 'undefined' && category.indexOf(':') !== -1) {
		categoryVariant = category.split(':')[1];
		category = category.split(':')[0];
	}
	
	var quickSearchLinks = document.getElementsByClassName('nek-faq-quicksearch');
	if (quickSearchLinks.length > 0) {
		if (typeof category !== 'undefined') {
			var sectionsApiUrl = faqBaseUrl + '/wp-json/nek/v1/get/sections?category=' + category;
			nekHttpGetAsync(sectionsApiUrl, nekEnableQuicksearchLinks);
		}
		for (var i = 0; i < quickSearchLinks.length; i++) {
			quickSearchLinks[i].addEventListener('click', nekPostMsgForSearchFaq, false);
			quickSearchLinks[i].setAttribute('aria-hidden', 'true');
			quickSearchLinks[i].style.display = 'none';
		}
	}
});

function faq_slide_in(activeFAQCategory, src_text, activeCategoryVariant = '') {
	if (!nekOverlayDiv.classList.contains('nek-active')) {
		if (nekOverlayDiv.innerHTML == '') {
			var iFrame_src =
				activeFAQCategory !== undefined
					? faqBaseUrl + '/standarder/faq/iframe/?opentab=' + activeFAQCategory + '&src=' + src_text + '&variant=' + activeCategoryVariant
					: faqBaseUrl + '/standarder/faq/iframe/';
			
			nekOverlayDiv.innerHTML =
				'<div id="nek-faq-wrapper"><iframe src="' +
				iFrame_src +
				'" id="nek-faq-iframe" name="nekFaqIframe" width="500" height="1000" scrolling="no"></iframe><span id="nek-loading-title"><span>Loading</span></span></div>';
			
			window.setTimeout(function () {
				nekOverlayDiv.classList.add('nek-loading');
			}, 200);
			
			nekFaqIframe = document.getElementById('nek-faq-iframe').contentWindow;
			
			document.getElementById('nek-faq-iframe').onload = function () {
				nekFaqIframe.postMessage(
					{ faqEventName: 'loading', loadingBoolean: true },
					postMessageTargetDomain
				);

				var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
				var ih = iOS ? screen.height : window.innerHeight;

				nekFaqIframe.postMessage(
					{ faqEventName: 'height', parentClientHeight: ih },
					postMessageTargetDomain
				);

				window.setTimeout(function () {
					nekFaqIframe.postMessage(
						{ faqEventName: 'loading', loadingBoolean: false },
						postMessageTargetDomain
					);
					nekOverlayDiv.classList.add('nek-active');
					nekOverlayDiv.classList.remove('nek-loading');
				}, 500);
				
				if (src_text !== undefined && src_text !== '') {
					nekFaqIframe.postMessage(
						{ faqEventName: 'search', s: src_text, cat: activeFAQCategory },
						postMessageTargetDomain
					);
				}
			};
		} else {
			window.setTimeout(function () {
				nekOverlayDiv.classList.add('nek-animate-out');
				window.setTimeout(function () {
					nekOverlayDiv.classList.add('nek-active');
					nekOverlayDiv.classList.remove('nek-animate-out');
				}, 300);
			}, 200);
		}

		return;
	}
}

var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
var eventer = window[eventMethod];
var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';

eventer(
	messageEvent,
	function (event) {
		if (event.origin !== faqBaseUrl) return false;
		var eventData = event.data;
		var embeddedButton = document.getElementById('nek-anchored-button');
		switch (eventData.name) {
			case 'closeNEKFAQ':
				nekOverlayDiv.classList.add('nek-animate-out');
				nekOverlayDiv.classList.remove('nek-active');
				window.setTimeout(function () {
					nekOverlayDiv.classList.remove('nek-animate-out');
					if (typeof embeddedButton !== 'undefined' && embeddedButton !== null) {
						embeddedButton.classList.remove('nek-hidden');
						embeddedButton.style.display = 'block';
					}
				}, 500);
				break;
		}
	},
	false
);

window.addEventListener(
	'resize',
	function () {
		var nekFaqIframe = document.getElementById('nek-faq-iframe');
		if (nekFaqIframe !== null) {
			var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
			var ih = iOS ? screen.height : window.innerHeight;
			nekFaqIframe.contentWindow.postMessage(
				{ faqEventName: 'resize', parentClientHeight: ih },
				postMessageTargetDomain
			);
		}
	},
	false
);
